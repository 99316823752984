<template>
    <b-card :title="$t('add_new_user')">
        <form @submit.prevent="addUser" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="first_name">{{ $t('first_name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="first_name" class="form-control" v-model="form.first_name" :class="{ 'is-invalid': form.errors.has('first_name') }">
                        <has-error :form="form" field="first_name" />
                    </div>
                    <div class="form-group">
                        <label for="last_name">{{ $t('last_name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="last_name" class="form-control" v-model="form.last_name" :class="{ 'is-invalid': form.errors.has('last_name') }">
                        <has-error :form="form" field="last_name" />
                    </div>
                    <div class="form-group">
                        <label for="surname">{{ $t('patronimic_name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="surname" class="form-control" v-model="form.surname" :class="{ 'is-invalid': form.errors.has('surname') }">
                        <has-error :form="form" field="surname" />
                    </div>
                    <div class="form-group">
                        <label for="email">{{ $t('email') }}<span class="text-danger">*</span></label>
                        <input type="text" name="email" class="form-control" v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }">
                        <has-error :form="form" field="email" />
                    </div>
                    <div class="form-group">
                        <label for="phone">{{ $t('phone') }}<span class="text-danger">*</span></label>
                        <input v-model="form.phone" v-mask="mask" class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }"/>
                        <has-error :form="form" field="phone" />
                    </div>
                    <div class="form-group">
                        <label for="card_number">{{ $t('card_number') }}</label>
                        <input type="text" name="card_number" class="form-control" v-model="form.card_number" :class="{ 'is-invalid': form.errors.has('card_number') }">
                        <has-error :form="form" field="card_number" />
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">{{ $t('password') }}<span class="text-danger">*</span></label>
                        <input type="password" name="password" class="form-control" v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }">
                        <has-error :form="form" field="password" />
                    </div>
                    <div class="form-group">
                        <label for="role">{{ $t('role') }}<span class="text-danger">*</span></label>
                        <select v-model="form.role" :class="{ 'is-invalid': form.errors.has('role') }" class="form-control">
                            <option :value="item" v-for="(item, index) in roles" :key="index">{{ item }}</option>
                        </select>
                        <has-error :form="form" field="role" />
                    </div>
                </b-col>
                <b-col md="6">
                    <!--<b-form-group :label="$t('avatar')" label-for="file-small" label-cols-sm="2" label-size="sm">
                        <b-form-file id="file-small" size="sm" v-model="form.avatar" :class="{ 'is-invalid': form.errors.has('avatar') }"></b-form-file>
                        <has-error :form="form" field="avatar" />
                    </b-form-group>-->
                </b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('add_new_user') }
    },
    data: () => ({
        mask: ['+38', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        form: new Form({
            first_name: '',
            last_name: '',
            surname: '',
            email: '',
            password: '',
            phone: '',
            card_number: '',
            role: ''
        }),
        roles: null
    }),
    mounted () {
        this.getRoles()
    },
    methods: {
        async addUser () {
            // add new user
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await this.form.post(apiUrl + 'users').then(resp => {
                Swal.fire({
                      icon: 'info',
                      title: 'Успішно додано',
                      reverseButtons: true,
                      confirmButtonText: this.$t('ok'),
                      cancelButtonText: this.$t('cancel')
                })
                this.$router.push({ name: 'users' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async getRoles () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'roles/list')
            this.roles = data
        }
    }
}
</script>
